import "data-layer-helper/dist/data-layer-helper";

export {
    scriptElementErzeugen,
    uriErstellen,
    registerDatalayerListener,
    b64DecodeUnicode
}

function uriErstellen(uri, suchParameter = {}) {
    const nUri = new URL(uri);
    const searchParams = new URLSearchParams();
    const spEntries = Object.entries(suchParameter);
    for (const [name, value] of spEntries) {
        searchParams.append(name, value);
    }
    nUri.search = searchParams.toString();
    return nUri;
}

function scriptElementErzeugen(uri, type, id) {
    const script = document.createElement("SCRIPT");
    script.src = uri;
    switch (type) {
        case "async":
            script.async = true;
            break;
        case "defer":
            script.defer = true;
            break;
        default:
            break;
    }
    script.id = id;
    return script;
}

function registerDatalayerListener(listener, listenToPast = true) {
    if (typeof window.newDataLayer === "undefined") {
        window.newDataLayer = [];
    }
    try {
        new DataLayerHelper(window.newDataLayer, listener, listenToPast);
    } catch (fehler) {
        
     }
    return true;
}

function b64DecodeUnicode(string) {
    // https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings#30106551
    return decodeURIComponent(atob(string).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
}