import { scriptElementErzeugen, uriErstellen, registerDatalayerListener, b64DecodeUnicode } from "./utils";
import Cookies from "js-cookie";

class FeedbackSmallComment extends HTMLElement {
  connectedCallback() {
    this.zenloopInitDone = false;
    if (this.zenloopHash === "") {
      return;
    }
    const hideFeedback = this.shouldFeedbackBeHidden(this.userPercentage);
    if(hideFeedback === true) {
      return;
    }

    Zenloop = window.Zenloop = window.Zenloop || {};
    this.removeAttribute("data-js");
    this.initMutationObserver();
      registerDatalayerListener((model, message) => {
        if (message.event === "page" || message.event === "page_view") {
          this.initZenloop(model);
        }
      }, true)
  }

  shouldFeedbackBeHidden(userPercentage) {
    const abTest = Cookies.get('ab_container') || 10;
    const untereGrenze = 10 - userPercentage;
    if(abTest > untereGrenze) {
      return false;
    }
    return true;
  }

  initZenloop(konfig) {
    if( this.zenloopInitDone === true ) {
      // einmal initialisieren reicht
      return;
    }
    this.zenloopInitDone = true;
    this.zenloopKonfiguration(konfig);
    this.zenloopWrapper = this.querySelector(".zenloop-wrapper");
    this.zenloopScriptUri = uriErstellen(this.zenloopApiUri, {
      "survey": this.zenloopHash
    });
    this.scriptElement = scriptElementErzeugen(this.zenloopScriptUri, "async", "zl-website-overlay-loader");
    this.zenloopWrapper.appendChild(this.scriptElement);
  }

  zenloopKonfiguration(konfig) {
    Zenloop.recipient = {};
    Object.assign(Zenloop.recipient, {
      identity_type: "custom",
    });
    try {
      this.setzeIdentifier(konfig['hashed-email']);
      this.setzeProperties(konfig);
    } catch (error) {}
  }

  setzeIdentifier(identifier) {
    if (typeof (identifier) !== "undefined") {
      Object.assign(Zenloop.recipient, {
        identity: identifier,
      });
    }
  }

  setzeProperties(daten) {
    try {
      const properties = {
        mandant: getNullSafe(daten['mandant-id']),
        auftragsnummer: getNullSafe(daten.ecommerce?.purchase?.actionField?.id),
        sortimente: getProductCategoriesString(daten.ecommerce?.purchase?.products),
        filialid: getNullSafe(daten.ecommerce?.purchase?.filialid),
        versandart: getNullSafe(daten.versandart),
        zahlart: getNullSafe(daten.zahlart),
        stage: getNullSafe(daten.stage),
        kundennummer: getNullSafe(daten.kundennummer),
        cid: getNullSafe(daten['client-id']),
        geraet: getNullSafe(daten['device-size']),
        sendungsnummer: getNullSafe(daten.sendungsnummer),
        plz: getNullSafe(daten.sendungsplz),
        matnr: getNullSafe(daten.id),
        artikelid: getNullSafe(daten.idalt),
        suchbegriff: getNullSafe(daten.suchbegriff),
        abTest: getNullSafe(daten['ab-test']),
        ContentsquareReplay: sessionStorage.getItem('ContentsquareReplay'),
      };
      if(typeof(daten.umsatzgutscheine) !== "undefined") {
        if(daten.umsatzgutscheine === "") {
          properties.couponNutzung = false;
        } else {
          properties.couponNutzung = false;
        }
      }
      const historieSuchbegriffe = getHistorieSuchbegriffe();
      if (historieSuchbegriffe) {
        Object.assign(properties, historieSuchbegriffe);
      }
      Object.assign(Zenloop.recipient, {
        properties
      });
    } catch(fehler) {
      
    }
  }

  initMutationObserver() {
    const options = {
      childList: true,
      subtree: true,
      attributes: true
    };
    const mutationObserver = new MutationObserver(this.mutationsVerarbeiten.bind(this));
    mutationObserver.observe(this.querySelector(".zenloop-wrapper"), options);
  }

  mutationsVerarbeiten(mutationsList, observer) {
    for (const mutation of mutationsList) {
      mutation.removedNodes.forEach((removedNode) => {
        if (removedNode.classList.contains('zl-score-page')) {
          // Aufgrund einer race condition kann der Schritt ohne if hier auf 2 gesetzt werden, nachdem er unten auf 3 gesetzt wurde
          if (this.umfrageSchritt < 2) {
            this.umfrageSchritt = 2;
          }
        }
      });

      mutation.addedNodes.forEach((added_node) => {
        // Observer kann im letzten Schritt beendet werden
        // Hinweis: Wenn wir für zukünftige Umfragen den "Zurück Button" nutzen wollen, darf der Observer nicht beendet werden.
        if (added_node.classList.contains('zl-thank-you-page')) {
          this.umfrageSchritt = 3;
          observer.disconnect();
        }
      });
    }
  }

  set umfrageSchritt(neuerWert) {
    this.setAttribute("data-aktiver-schritt", neuerWert);
  }

  get umfrageschritt() {
    return this.getAttribute('data-aktiver-schritt');
  }

  get zenloopHash() {
    const token = this.dataset.token || "";
    return token;    
  }

  get zenloopApiUri() {
    return this.dataset.zenloopApiUri;
  }
  
  get userPercentage() {
    return this.dataset.userPercentage;
  }
}

function getNullSafe(parameter) {
  try {
    if( typeof(parameter) === "undefined" ) {
      return "";
    }
    // Immer einen String zurückgeben – Ja, toString() gibt es auch für Strings ;-)
    return  parameter.toString();
  } catch (e) {
    return "";
  }
}

function getProductCategoriesString(products) {
  if(typeof(products) === "undefined") {
    return "";
  }
  return Object.values(products).map(product => product.shopbezeichnung).join(",");
}

function getHistorieSuchbegriffe() {
  try {
    const properties = {};
    const historieSuchbegriffe = readSuchbegriffeHistorieCookie();
    if (historieSuchbegriffe) {
      historieSuchbegriffe.forEach((suchbegriff, index) => {
        properties[`suchbegriff${index}`] = suchbegriff;
      })
    }
    return properties;
  } catch(fehler) {}
}

function readSuchbegriffeHistorieCookie() {
  const kususanCookieWert = Cookies.get('KUSUSAN');
  if (kususanCookieWert) {
    const suchbegriffe = b64DecodeUnicode(kususanCookieWert).split('\u{2634}');
    return suchbegriffe.slice(1);
  }
  return null;
}

if (!customElements.get("feedback-small-comment")) {
  customElements.define("feedback-small-comment", FeedbackSmallComment);
}
